import React, { useState } from 'react';

const sampleData = [
  {
    vehicleNo: 'ABC123',
    entryDateTime: '2023-09-25 08:30',
    exitDateTime: '2023-09-25 10:30',
    entryImage: 'https://vehiclenumber.arustu.com/Content/Uploads/ac29a278-5754-4c81-8780-0cc98f5cc58c_user_picture.jpg',
    exitImage: '',
    totalTime: '2 hours',
  },
  {
    vehicleNo: 'XYZ456',
    entryDateTime: '2023-09-25 09:00',
    exitDateTime: '2023-09-25 11:00',
    entryImage: 'https://vehiclenumber.arustu.com/Content/Uploads/ac29a278-5754-4c81-8780-0cc98f5cc58c_user_picture.jpg',
    exitImage: 'https://vehiclenumber.arustu.com/Content/Uploads/ac29a278-5754-4c81-8780-0cc98f5cc58c_user_picture.jpg',
    totalTime: '2 hours',
  },
  // Add more sample entries as needed
];

const Dashboard = () => {
  const [filters, setFilters] = useState({
    vehicleNo: '',
    entryDateTime: '',
    exitDateTime: '',
  });
  const [zoomedImage, setZoomedImage] = useState(null);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filteredData = sampleData.filter((data) =>
    Object.keys(filters).every((key) =>
      data[key].toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );

  return (
    <div className="container-fluid mt-4 col-12">
      <h2 className="mb-4">Vehicle Entry Dashboard</h2>
      <div className="row mb-3">
        <div className="col">
          <input
            type="text"
            name="vehicleNo"
            className="form-control"
            placeholder="Filter by Vehicle No"
            value={filters.vehicleNo}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col">
          <input
            type="text"
            name="entryDateTime"
            className="form-control"
            placeholder="Filter by Entry DateTime"
            value={filters.entryDateTime}
            onChange={handleFilterChange}
          />
        </div>
        <div className="col">
          <input
            type="text"
            name="exitDateTime"
            className="form-control"
            placeholder="Filter by Exit DateTime"
            value={filters.exitDateTime}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      <table className="table table-bordered table-striped">
        <thead className="thead-light">
          <tr>
            <th>Vehicle No</th>
            <th>Entry DateTime</th>
            <th>Exit DateTime</th>
            <th>Entry Image</th>
            <th>Exit Image</th>
            <th>Total Time</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((data, index) => (
            <tr key={index}>
              <td>{data.vehicleNo}</td>
              <td>{data.entryDateTime}</td>
              <td>{data.exitDateTime}</td>
              <td>
                <img
                  src={data.entryImage}
                  alt="Entry"
                  className="img-thumbnail"
                  onClick={() => setZoomedImage(data.entryImage)}
                  style={{ cursor: 'pointer', width: '100px', height: 'auto' }}
                />
              </td>
              <td>
                <img
                  src={data.exitImage}
                  alt="Exit"
                  className="img-thumbnail"
                  onClick={() => setZoomedImage(data.exitImage)}
                  style={{ cursor: 'pointer', width: '100px', height: 'auto' }}
                />
              </td>
              <td>{data.totalTime}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {zoomedImage && (
        <div className="zoomed-image" onClick={() => setZoomedImage(null)}>
          <span className="close-btn" onClick={() => setZoomedImage(null)}>×</span>
          <img
            src={zoomedImage}
            alt="Zoomed"
            className="img-fluid"
            onClick={(e) => e.stopPropagation()} 
          />
        </div>
      )}


    </div>
  );
};

export default Dashboard;
