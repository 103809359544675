// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    // Using a mock API
    try {
      // Simulating an API call
      const response = await axios.post('https://jsonplaceholder.typicode.com/posts', {
        username,
        password,
      });

      // For simulation, we assume "admin" is a valid login
      if (username === 'admin' && password === 'admin') {
        console.log('Login successful:', response.data); // Simulated successful response
        navigate('/vehicle'); // Navigate to the dashboard
      } else {
        setError('Invalid username or password');
      }
    } catch (err) {
      setError('An error occurred during login');
      console.error(err);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="border p-4 rounded bg-white" style={{ width: '400px' }}>
        <h2 className="text-center">Login</h2>
        <form onSubmit={handleLogin} autoComplete="off">
          {/* Dummy inputs */}
          <input type="text" style={{ display: 'none' }} name="fakeusernameremembered" />
          <input type="password" style={{ display: 'none' }} name="fakepasswordremembered" />

          <div className="mb-3">
            <label className="form-label">Username</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              autoComplete="nope" // Use an uncommon value
              name="username-field-xyz" // Unique name
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="new-password" 
              name="password-field-abc" // Unique name
            />
          </div>
          {error && <p className="text-danger">{error}</p>}
          <button type="submit" className="btn btn-primary w-100">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
