import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Header = () => {
    const location = useLocation();
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <a className="navbar-brand" href="#">Mines</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto">
                        <li className={`nav-item ${location.pathname === '/vehicle' ? 'active' : ''}`}>
                            <Link to="/vehicle">
                                Vehicle Entry
                            </Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/Webbridge' ? 'active' : ''}`}>
                            <Link to="/Webbridge">
                                Webbridge Entry
                            </Link>
                        </li>
                        <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                            <Link to="/">
                                LogOut
                            </Link>
                        </li>
                    </ul>

                </div>
            </nav>

        </>
    )
}

export default Header