import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Login from './Components/Pages/Login';
import Dashboard from './Components/Pages/Dashboard';
import Header from './Components/Pages/Header';
import VehicleEntry from './Components/VehicleEntry';
import "react-datepicker/dist/react-datepicker.css";
import WebEntry from './Components/Pages/WebEntry';

function App() {
  const location = useLocation();

  return (
    <>

      {location.pathname !== '/' && <Header />}
      <div id="main_content">
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/vehicle" element={<VehicleEntry />} />
          <Route exact path="/Webbridge" element={<WebEntry />} />
          {/* <Route exact path="/WebbridgeEntry" element={<Webbridgentry />} /> */}
        </Routes>
      </div>
    </>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
